<template>
  <div class="medium-page">
    <!-- If we don't have the campaignTracking data just yet -->
    <template v-if="!overview">
      <div class="mx-auto" style="max-width: 360px">
        <lottie-animation loop key="antenna" file="loading-files-StOdYbIRa1.json" />
      </div>
    </template>

    <!-- Otherwise -->
    <template v-else>
      <!-- If we don't have any influencer in this campaign -->
      <template v-if="overview.influencers === 0">
        <!-- The header buttons -->
        <div class="d-flex justify-space-between">
          <!-- Show the go back button -->
          <v-btn text color="primary" @click="$router.push({ name: 'CampaignTrackingIndex' })">
            <v-icon left> arrow_back </v-icon>

            Campaigns
          </v-btn>
        </div>

        <!-- Show an animation first -->
        <div class="mx-auto" style="max-width: 360px">
          <lottie-animation loop key="shaking-box" file="106964-shake-a-empty-box.json" />
        </div>

        <!-- Show the text -->
        <div class="text-center text-h6 font-weight-bold">You don't have any influencer in this Campaign</div>

        <div class="text-center mt-2">Start by adding influencers below</div>

        <!-- Show the button -->
        <div class="text-center">
          <v-btn depressed class="mt-6" color="primary" @click="shouldShowAddInfluencerDialog = true">
            <v-icon left> add </v-icon>

            Add Influencers
          </v-btn>
        </div>
      </template>

      <!-- Otherwise, show the router-view child component -->
      <div v-else class="campaign-tracking-child-view">
        <router-view
          :key="reloadKey"
          :daywise="daywise"
          :overview="overview"
          :categories="categories"
          @refreshData="fetchDetails"
          @refreshPosts="refreshPosts"
          :is-refreshing-posts="isRefreshingPosts"
          @triggerAddInfluencer="handleAddInfluencer"
          :selected-posts-view-tab="selectedPostsViewTab"
          @selectedPostsViewTabChange="(v) => (selectedPostsViewTab = v)"
          :selected-stories-view-tab="selectedStoriesViewTab"
          @selectedStoriesViewTabChange="(v) => (selectedStoriesViewTab = v)"
        />
      </div>

      <!-- Show the add influencers dialog -->
      <add-influencers
        v-model="shouldShowAddInfluencerDialog"
        :selected-platform="selectedPlatform"
        :overview="overview"
        :include-posts-input="true"
        module="campaignTracking"
        @refresh="fetchDetails"
      />
    </template>
  </div>
</template>

<script>
// Import children components
const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")
const AddInfluencers = () => import(/* webpackChunkName: "crm-add-influencers" */ "@/components/crm/AddInfluencers.vue")

// Import helper functions
import messageEvents from "@/helpers/messageEvents"
import { getClientID } from "@/helpers/clientHelper"

// Define the key for handling subscriptions
const subscriptionId = Symbol("CampaignTracking/View")

// Export the SFC
export default {
  // Name of the component
  name: "CampaignTrackingView",

  // Register children components
  components: {
    AddInfluencers,
    LottieAnimation,
  },

  // Define local data variables
  data: () => ({
    // Whether or not to show the add influencer dialog
    shouldShowAddInfluencerDialog: false,

    // Whether or not is refreshing posts
    isRefreshingPosts: false,

    // If the data is being loaded
    isLoading: false,

    // The reload key to make sure the UI is always up-to-date
    reloadKey: null,

    // The selected tab in posts
    selectedPostsViewTab: "table",

    // The selected tab in stories
    selectedStoriesViewTab: "table",

    // Whether or not the listener has been set
    hasEchoEventBeenSet: false
  }),

  // Define readonly data variables
  computed: {
    /**
     * Whether or not the user is authenticated
     *
     * @returns {Boolean}
     */
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"]
    },

    /**
     * Get the model data from Vuex store
     *
     * @returns {Object}
     */
    overview() {
      // Get the data from store
      return this.$store.getters["campaignTracking/detailByUuid"](this.$route.params.uuid)
    },

    /**
     * Get the currently selected platform
     *
     * @returns {String|Null}
     */
    selectedPlatform() {
      return this.overview ? this.$store.getters["campaignTracking/selectedPlatformById"](this.overview.model.id) : null
    },

    /**
     * Get the selected platform's daywise values
     *
     * @returns {Object}
     */
    daywise() {
      return this.overview.daywise[this.selectedPlatform || "all"]
    },

    /**
     * Get the selected platform's categories values
     *
     * @returns {Array}
     */
    categories() {
      return this.overview.categories[this.selectedPlatform || "all"]
    },
  },

  // Define local method functions
  methods: {
    /**
     * When called, fetch and update the campaign data in Vuex store
     *
     * @returns {void}
     */
    async fetchDetails() {
      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isLoading = true

      // Also, reload the children view
      this.reloadKey = Symbol()

      // Wait for the load to be completed
      await this.$store.dispatch("campaignTracking/fetchDetails", this.$route.params.uuid)

      // Set the default value for platforms
      this.$store.dispatch("campaignTracking/updateSelectedPlatform", {
        id: this.overview.model.id,
        value: this.overview?.model?.platforms?.[0] || null
      })

      // Hide the loaders
      this.$store.dispatch("loaders/remove", loaderId)
      this.isLoading = false

      // If we do have the data now
      if (this.isAuthenticated && this.hasEchoEventBeenSet === false && this.overview?.model?.id) {
        // Register laravel echo events
        window.echo.private(`campaignTracking.${this.overview.model.id}`).listen("CampaignEvent", this.handleCampaignEvent)

        // Mark it as set
        this.hasEchoEventBeenSet = true
      }

      // If the user has write access
      if (this.overview?.canUserWrite) {
        // Make a request to fetch updated cost value
        try {
          // Use helper function
          const response = await axios({
            url: `/api/campaign-tracking/${this.overview.model.id}/unlock`,
            method: "POST",
            data: {
              clientId: getClientID(),
              triggerId: "DRY-RUN",
              onlyLink: true
            }
          })

          // Update the cost value
          this.$store.dispatch("campaignTracking/updateCost", {
            id: this.overview.model.id,
            cost: response.data.cost
          })
        } catch (error) {
          // Catch an error
          // Log it to the console
          logger({ type: "CampaignTracking/Unlock Error Only Link", error })

          // Show a message
          this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred" })
        }
      }
    },

    /**
     * Show the dialog box for adding influencers
     *
     * @returns {void}
     */
    handleAddInfluencer() {
      // If the selected platform is not set
      if (!this.selectedPlatform) {
        // Select the first platform available in the list
        this.$store.dispatch("campaignTracking/updateSelectedPlatform", {
          id: this.overview.model.id,
          value: this.overview?.model?.platforms?.[0] || null
        })
      }

      // Show the dialog
      this.shouldShowAddInfluencerDialog = true
    },

    /**
     * Make a request to fetch campaign posts
     *
     * @returns {void}
     */
    async refreshPosts() {
      // Disable the button
      this.isRefreshingPosts = true

      // Try making a network request
      try {
        // Use helper function
        await axios({
          url: `/api/campaign-tracking/${this.overview.model.id}/refresh`,
          method: "POST"
        })

        // If request is successful
        this.$store.dispatch("toasts/add", { text: "Please wait while we fetch your posts" })
      } catch (error) {
        // Catch an error
        // Log the error
        logger({ type: "CampaignTracking/Refresh Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred!" })
      } finally {
        // Nonetheless
        this.isRefreshingPosts = false
      }
    },

    /**
     * When triggered, refresh the data to fetch updated list of influencers
     *
     * @returns {void}
     */
    handleNotificationRefreshAction() {
      // Dispatch a request to create a network request
      this.fetchDetails()
    },

    /**
     * Handle the message event for campaign views
     *
     * @param {Object} event
     * @returns {void}
     */
    handleMessageEvent(event) {
      // If the import just started
      if (event.key === "influencers-import-started") {
        // Fire a notification
        this.$store.dispatch("notifications/add", {
          text: event.localData.text || "Your influencers are being added to the campaign!",
          type: "info",
          icon: "lightbulb",
          event: {
            module: event.module,
            type: event.type,
            key: event.key
          }
        })
      }

      // If a chunk of import has been completed
      if (event.key === "influencers-import-completed") {
        // Fire a notification
        this.$store.dispatch("notifications/add", {
          text: event.localData.text || "A bunch of your influencers have been added, check progress!",
          type: "success",
          icon: "check_circle",
          button: {
            text: "Refresh",
            action: this.handleNotificationRefreshAction
          },
          event: {
            module: event.module,
            type: event.type,
            key: event.key
          }
        })
      }
    },

    /**
     * Handle the campaign event value
     *
     * @param {Object} event
     * @returns {void}
     */
    handleCampaignEvent(event) {
      // If the case is for "tracker-post"
      if (event.for === "tracker-post") {
        // If we refreshed the post values
        if (event.key === "refresh-tracker-post") {
          // Try to find the model if it exists
          const post = this.data.posts.find((search) => search.id === event.localData.modelId)

          // If it exists
          if (post) {
            // Update the stat values
            this.$store.dispatch("campaignTracking/updatePost", {
              id: this.data.model.id,
              post: {
                ...post,

                likes: event.localData.likes,
                dislikes: event.localData.dislikes,
                comments: event.localData.comments,
                views: event.localData.views,
                shares: event.localData.shares,
                saves: event.localData.saves,

                edited_manually: false
              }
            })

            // Show a notification regarding this
            this.$store.dispatch("notifications/add", {
              text: "Synced post stats values",
              type: "success",
              icon: "update",
              event: {
                module: "campaign-tracking",
                type: "refresh-post",
                key: "status-success"
              }
            })
          }
        }
      }
    },

    /**
     * When triggered, refresh the entire report data
     *
     * @returns {void}
     */
    handleReloadEvent() {
      // Dispatch a request to create a network request
      this.fetchDetails()
    },
  },

  /**
   * As soon as the component data has been created
   *
   * @returns {void}
   */
  created() {
    // Dispatch a request to create a network request
    this.fetchDetails()

    // Register a subscriber for messageEvents
    messageEvents.register({
      id: subscriptionId,
      module: "campaign-tracking",
      type: "all",
      key: "all",
      validator: (event) => event.module === "campaign-tracking",
      callback: this.handleMessageEvent
    })

    // Listen for global custom window event to reload refresh key
    window.addEventListener("reloadCampaignTrackingView", this.handleReloadEvent)
  },

  /**
   * Right before the component is about to be un-rendered
   *
   * @returns {void}
   */
  beforeDestroy() {
    // If we did listen to pusher channel
    if (this.hasEchoEventBeenSet && this.overview && this.overview.model) {
      // Disconnect from the pusher channel
      window.echo.leave(`campaignTracking.${this.overview.model.id}`)
    }

    // De-register the subscriber for messageEvents
    messageEvents.deregister(subscriptionId)

    // De-register the global custom window event
    window.removeEventListener("reloadCampaignTrackingView", this.handleReloadEvent)
  }
}
</script>
